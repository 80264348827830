<template>
<v-container fluid class="pa-0">

    <v-container class="d-flex flex-wrap justify-center align-center">

        <v-col cols="12 mb-5">
            <h1 class="page-title">Clube</h1>
        </v-col>
        <v-col v-if="!items.length === 0" class="d-flex flex-column justify-center align-center" cols="12">
    <v-img src="../../../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
    <h5 class="pa-0">Carregando...</h5> 
  </v-col>
        <v-col cols=" mb-5" sm="12" md="12" lg="10" xl="7">
            <v-col cols="12 mb-5">
                <v-carousel cycle height="500" hide-delimiters class="fill-height" show-arrows-on-hover>
                    <v-carousel-item v-for="(item, index) in items" :key="index">
                        <v-sheet height="100%">
                            <v-row class="fill-height" align="center" justify="center">
                                <v-img :src="item.img" min-height="300" max-height="600" class="fill-height"></v-img>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>

            </v-col>

            <v-col cols="12">
                <v-row class="d-flex flex-wrap justify-center align-center">
                    <v-col cols="12 " sm="12" md="6" lg="6" xl="6">
                        <div>

                            <template v-for="({ icon, text, title: t }, i) in address">
                                <v-col :key="i" class="pb-10 pt-10 black--text">
                                    <v-row>
                                        <v-icon class="mr-3" large>{{icon}}</v-icon>
                                        <div v-html="text" />

                                    </v-row>
                                </v-col>

                                <v-divider v-if="i + 1 !== address.length" :key="`divider-${i}`" class="my-2" />
                            </template>

                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d943.7038059368748!2d-48.28146292490158!3d-18.89527670456098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a4443303d140f5%3A0x5f8a4232a5b7bb50!2sR.%20Domingos%20Ant%C3%B4nio%20Cal%C3%A1bria%2C%20240%20-%20Pres.%20Roosevelt%2C%20Uberl%C3%A2ndia%20-%20MG%2C%2038401-222!5e0!3m2!1spt-BR!2sbr!4v1596899941177!5m2!1spt-BR!2sbr" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen aria-hidden="false" tabindex="0"></iframe>
                    </v-col>

                </v-row>
            </v-col>


        </v-col>

    </v-container>

</v-container>
</template>

<script>
export default {
    name: 'club',
     metaInfo: { 
    title: "SEEB | Clube",
     meta: [
      { name: 'description', content: 'Clube do sindicato dos bancarios de Uberlândia.' }
    ]
    
  },
    components: {
        Footer: () => import("@/layouts/web/commons/Footer.vue")
    },
    data: () => ({
       address: [{
                icon: "mdi-map-marker-outline",
                title: "Address",
                text: "<b>ENDEREÇO CLUBE</b><br>R. Domingos Antônio Calábria,  240. - Roosevelt<br> Uberlândia - MG, 3215-1729"
            },
            {
                icon: "mdi-map-marker-outline",
                title: "Address",
                text: "<b>ENDEREÇO CASA DE MADEIRA</b><br>R. Professor Leônidas de Castro serra, 131. - Roosevelt<br> Uberlândia - MG, 38401-224"
            },
            {
                icon: "mdi-cellphone",
                title: "Phone",
                text: "<b>TELEFONE</b><br>(34) 3236 7277<br>(34) 3236-3803 - Fax "
            }

        ],
        items: [{
                img: require('../../../../assets/clube0.jpeg'),
            },
            {
                img: require('../../../../assets/clube1.jpeg'),
            },
            {
                img: require('../../../../assets/clube2.jpeg'),
            },
            {
                img: require('../../../../assets/clube3.jpeg'),
            },
            {
                img: require('../../../../assets/clube4.jpeg'),
            },
            {
                img: require('../../../../assets/clube6.jpeg'),
            },
            {
                img: require('../../../../assets/clube7.jpeg'),
            },
            {
                img: require('../../../../assets/clube8.jpeg'),
            },

        ],

    })

}
</script>

<style lang="sass" scoped>
    .page-title 
        background: #114979 
        color: #fff 
        font-family: Raleway,sans-serif 
        font-weight: 700 
        line-height: 1 
        margin: 0 
        font-size: 1.75rem 
        padding: 1.25rem 1.25rem
    </style>
